import axios from "axios"
import { signInWithEmailAndPassword } from "firebase/auth"
import React from "react"
import { auth } from "../../../firebase-config"
import { getUIDMain } from "../../service/user-service"
import { submitContactDataFBPortal_CONTACTS_00 } from "../../../firebase/firebase-portal-contacts-00" 
import { ENABLE_ERROR_LOGGING } from "../../settings/project-settings"


export default() => {
    const [currentUser, setCurrentUser] = React.useState("NOT LOGGED IN!")
    const [token, setToken] = React.useState("NOT LOGGED IN!")
    React.useEffect(()=>{
        auth.onAuthStateChanged(()=>{
            if( auth.currentUser ) setCurrentUser(auth.currentUser.uid)
            else setCurrentUser("failed to login")
            
            let anyAuth : any = auth
            if( anyAuth?.currentUser?.accessToken )
                setToken( anyAuth.currentUser.accessToken )
            
        })
        
    })

    return (
        <>
            <div> logged in as: {currentUser}</div>
            <div> logged in as: {JSON.stringify((auth as any)?.currentUser?.email)}</div>
            
            <QuickLogin >
                Login
            </QuickLogin>
            
            
            
            <Button 
                onClick={async ()=>{
                   

                    await submitContactDataFBPortal_CONTACTS_00( getUIDMain(),{"test" : true } )
                }}
            >
                run portal test - dbPortal001
            </Button>


            <br/>
            {token}
        </>
    )
}

export const QuickLogin = ( props : { 
    children ?: any 
}) => {
    return (
        <>
            <div style={{ display : 'block'}}></div>
            <button 
            style={{ width : 200, height : 45 , marginBottom : 0, marginTop : 20 }}
            onClick={ ()=>{
                signInWithEmailAndPassword(auth,"newuser-test@email.com", "password").then(( res )=>{
                    alert("success!")
                    if( ENABLE_ERROR_LOGGING ) console.log(res)
                })
            }}>
            {props.children}
        </button>
        
        </>
    )
}




export const quickCall = async ( props : { 
    key : string,
    params ?: any
    token?: string,
    endpoint:string,
    config ?: any, 
    onSuccess ?: ( data : any ) => void 

}) => {
    const CONFIG = props.config ? props.config : {
        headers: { 
            Authorization: `Bearer ${props.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*'
        },crossDomain: true,
    };

    const post = {
        key : props.key ? props.key : '',
        params : props.params ? props.params : {  }
    }
    return axios.post( props.endpoint , post, CONFIG )
    .then((response) => {  
        if( props.onSuccess )
            props.onSuccess( response )
        else if( ENABLE_ERROR_LOGGING ) console.log(response.data);
        return response.data
    })
    .catch(e => {
        if( ENABLE_ERROR_LOGGING ) console.log('Error: ', e)
        return false
    });
}



export const QuickButton = ( props : { 
    children ?: any, 
    keyValue : string,
    params ?: any
    token?: string,
    endpoint:string,
    config ?: any, 
    onSuccess ?: ( data : any ) => void 

}) => {

    const CONFIG = props.config ? props.config : {
        headers: { 
            Authorization: `Bearer ${props.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*'
        },crossDomain: true,
    };

    if( ENABLE_ERROR_LOGGING ) console.log(CONFIG)
    return (
        <>
            <div style={{ display : 'block'}}></div>
            <button 
            style={{ width : 200, height : 45 , marginBottom : 0, marginTop : 20 }}
            onClick={ ()=>{
                
                axios.post( props.endpoint , {
                    key : props.keyValue ? props.keyValue : '',
                    params : props.params ? props.params : { testParam : -1 }
                }, CONFIG )
                .then((response) => {
                    
                    if( props.onSuccess )
                        props.onSuccess( response )
                    else if( ENABLE_ERROR_LOGGING ) console.log(response.data);


                })
                .catch(e => {
                    if( ENABLE_ERROR_LOGGING ) console.log('Error: ', e)
                });
                
    
            }}>
            {props.children}
        </button>
        
        </>

    )
}


function getTemporaryToken (){
    return 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjU4ODI0YTI2ZjFlY2Q1NjEyN2U4OWY1YzkwYTg4MDYxMTJhYmU5OWMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdGVzdC01MDAtOTk5OSIsImF1ZCI6InRlc3QtNTAwLTk5OTkiLCJhdXRoX3RpbWUiOjE2Nzg5MjAwNDUsInVzZXJfaWQiOiJ6OHVuN21lMmRBU0RDOFo5Q3o4VVhKNzRPc2IyIiwic3ViIjoiejh1bjdtZTJkQVNEQzhaOUN6OFVYSjc0T3NiMiIsImlhdCI6MTY3ODkyMDA0NSwiZXhwIjoxNjc4OTIzNjQ1LCJlbWFpbCI6InRlc3RhcGlnYXRld2F5QHRlc3RpbmcuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbInRlc3RhcGlnYXRld2F5QHRlc3RpbmcuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.VG7HrhFw5goK3q-hRGq_BK265VYIOC7eQ_tdJVx7yyGYqGojSGDr-Ug5WJdAGL3An9FxHnjS2G3ohl_UApEDWUx8YkD89ceglAr32fCvoc9PYPPsVPdFpjcJoYN2QEQavxkozT41uxZEO1GZp9Az8zjBMjtWL-6heI9G93I7E9owli0zltHFfUWpDUER9DINfniymr2PeeKWkpWz9cyQct_OigivokCmDfmJAhsW9gvuqA7Ku6I05NGK-OsjLkBSCgKwzzvHKCfIs5A0xUfN-mpbsFq0GiTD_mRmOWvYxz1kiiFX0V27MLGIjO8uRU5SYWhbG6kR_KfbXhqe2qmzQA'
}




const Button = ( props : { 
    children ?: any, 
    onClick ?: any 
}) => {
    
    return (
        <>
            <div style={{ display : 'block'}}></div>
            <button 
            style={{ width : 200, height : 45 , marginBottom : 0, marginTop : 20 }}
            onClick={props.onClick}>
            {props.children}
        </button>
        
        </>

    )
}