import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore, updateDoc, doc, increment, arrayUnion } from '@firebase/firestore'
import { getStorage } from "firebase/storage";
import { addVisitTimeFBPortal, clearContactDataFBPortal, clearTempDataFBPortal, getContactsDataFBPortal, getCurrentUserPortal, getGeolocationDataFBPortal, getTempDataFBPortal, getUIDPortal, getUserEmailPortal, incrementCounterPortal, isLoggedInAndRegisteredPortal, isLoggedInPortal, saveTempDataFBPortal, submitContactDataFBPortal, submitGeolocationDataFBPortal } from "./firebase-portal-functions";

const USE_FIREBASE_PORTAL_NAME = 'portal-contacts-00'

const firebaseConfig = {
  apiKey: "AIzaSyCV_F8fmyIskIX3WXLCQ4qATFAgeAKF9_Y",
  authDomain: "slap-kard-portal-contacts-00.firebaseapp.com",
  projectId: "slap-kard-portal-contacts-00",
  storageBucket: "slap-kard-portal-contacts-00.appspot.com",
  messagingSenderId: "872009996584",
  appId: "1:872009996584:web:beb8bc6eb7c63cab44981d"
};

export const firestoreAppPortalContacts00               = initializeApp(firebaseConfig,  USE_FIREBASE_PORTAL_NAME );
export const portalAuthContacts00                       = getAuth(firestoreAppPortalContacts00);
export const portalStoragePortalContacts00              = getStorage(firestoreAppPortalContacts00)
export const dbPortalContacts00                         = initializeFirestore(firestoreAppPortalContacts00, {
    //experimentalForceLongPolling: false,
    cacheSizeBytes: 1048576
});

const USE_FIREBASE_AUTH_PORTAL                          = portalAuthContacts00
const USE_FIREBASE_DB_PORTAL                            = dbPortalContacts00

export const isLoggedInPortal_CONTACTS_00               = () => isLoggedInPortal( USE_FIREBASE_AUTH_PORTAL )
export const getCurrentUserPortal_CONTACTS_00           = () => getCurrentUserPortal( USE_FIREBASE_AUTH_PORTAL )
export const getUserEmailPortal_CONTACTS_00             = () => getUserEmailPortal( USE_FIREBASE_AUTH_PORTAL )
export const getUIDPortal_CONTACTS_00                   = () => getUIDPortal( USE_FIREBASE_AUTH_PORTAL )
export const isLoggedInAndRegisteredPortal_CONTACTS_00  = () => isLoggedInAndRegisteredPortal( USE_FIREBASE_AUTH_PORTAL )
export const incrementCounterPortal_CONTACTS_00         = async ( uid : string ) => await incrementCounterPortal( USE_FIREBASE_DB_PORTAL, uid )
export const getGeolocationDataFBPortal_CONTACTS_00     = async ( uid : string ) => await getGeolocationDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const getContactsDataFBPortal_CONTACTS_00        = async ( uid : string ) => await getContactsDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const clearContactDataFBPortal_CONTACTS_00       = async ( uid : string ) => await clearContactDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const getTempDataFBPortal_CONTACTS_00            = async ( uid : string ) => await getTempDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const clearTempDataFBPortal_CONTACTS_00          = async ( uid : string ) => await clearTempDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const submitGeolocationDataFBPortal_CONTACTS_00  = async ( uid : string ) => await submitGeolocationDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const addVisitTimeFBPortal_CONTACTS_00           = async ( uid : string ) => await addVisitTimeFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const saveTempDataFBPortal_CONTACTS_00           = async ( uid : string, data : any  ) => await saveTempDataFBPortal( USE_FIREBASE_DB_PORTAL, uid, data ) 
export const submitContactDataFBPortal_CONTACTS_00      = async ( uid : string, contactData ?: any  ) => await submitContactDataFBPortal( USE_FIREBASE_DB_PORTAL, uid, contactData )